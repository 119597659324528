import React, { useState } from 'react';
import { supabase } from '../../supabaseClient'; // Asegúrate de que está correctamente importado
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Para redirigir

const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
`;

function InicioSesion() {
  const [credenciales, setCredenciales] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Hook para redirigir

  const handleInputChange = (e) => {
    setCredenciales({ ...credenciales, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Limpiar errores previos

    console.log("Enviando credenciales:", credenciales); // Verificar que los datos del formulario se están enviando

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: credenciales.email,
        password: credenciales.password,
      });

      console.log("Respuesta de Supabase:", data, error); // Verificar la respuesta de Supabase

      if (error) {
        console.error('Error al iniciar sesión:', error.message);
        setError('Error al iniciar sesión: ' + error.message);
        return;
      }

      if (data.user) {
        console.log('Usuario autenticado:', data.user);
        // Redirigir al dashboard si la autenticación es exitosa
        navigate('/Servicios'); //donde redirige
      } else {
        console.log('No se encontró usuario.'); // En caso de que no retorne usuario
      }

    } catch (err) {
      console.error('Error inesperado:', err);
      setError('Error inesperado: ' + err.message);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Iniciar Sesión</FormTitle>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormInput
            type="email"
            name="email"
            placeholder="Correo Electrónico"
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <FormInput
            type="password"
            name="password"
            placeholder="Contraseña"
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormButton type="submit">Iniciar Sesión</FormButton>
        {error && <ErrorMessage>{error}</ErrorMessage>} {/* Mostrar mensaje de error si existe */}
      </form>
    </FormContainer>
  );
}

export default InicioSesion;
