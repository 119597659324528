import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { supabase } from '../../supabaseClient'; // Asegúrate de tener configurado Supabase

const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function ModificarServicio({ id }) {
  const [service, setServicio] = useState({
    name: '',
    description: '',
    precio_unico: '',
    precio_dos_pagos: '',
    imagen: ''
  });

  useEffect(() => {
    // Obtener los detalles del servicio a modificar
    const fetchService = async () => {
      const { data, error } = await supabase
        .from('servicios')
        .select('*')
        .eq('id', id)
        .single();

      if (error) {
        console.error('Error al obtener el servicio:', error);
      } else {
        setServicio(data);
      }
    };

    fetchService();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setServicio({ ...service, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('servicios')
        .update({
          name: service.name,
          description: service.description,
          precio_unico: service.precio_unico,
          precio_dos_pagos: service.precio_dos_pagos
        })
        .eq('id', id); // Asegúrate de usar el ID correcto

      if (error) {
        console.error('Error al actualizar el servicio:', error);
        alert('Error al actualizar el servicio');
      } else {
        alert('Servicio actualizado exitosamente');
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  return (
    <FormContainer>
      <FormTitle>Modificar Servicios</FormTitle>
    <form onSubmit={handleSubmit}>
      <FormGroup>
      <FormInput
        type="text"
        name="name"
        value={service.name}
        onChange={handleInputChange}
        placeholder="Nombre del servicio"
      />
      </FormGroup>
      <formGroup>
      <FormInput
        type="text"
        name="description"
        value={service.description}
        onChange={handleInputChange}
        placeholder="Descripción"
      />
      </formGroup>
      <FormGroup>
     <FormInput
        type="number"
        name="precio_unico"
        value={service.precio_unico}
        onChange={handleInputChange}
        placeholder="Precio único"
      />
      </FormGroup>
      <FormGroup>
      <FormInput
        type="number"
        name="precio_dos_pagos"
        value={service.precio_dos_pagos}
        onChange={handleInputChange}
        placeholder="Precio en 2 pagos (opcional)"
      />
      </FormGroup>
      <FormButton type="submit"> Modificar Servicio</FormButton>
    </form>
    </FormContainer>
  );
}

export default ModificarServicio;
