import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
/*import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Selecciona el contenedor donde quieres montar la aplicación
const container = document.getElementById('root');

// Crea la raíz utilizando la nueva API de React 18
const root = createRoot(container);

// Renderiza la aplicación
root.render(<App />);
*/