import React from 'react'
import CrearServicios from './CrearServicios'
import ModificarServicios from './ModificarServicios'
import EliminarServicio from './EliminarServicio'

const Servicios = () => {
  return (
    <div className='dash'>
  <CrearServicios />
  <ModificarServicios />
  <EliminarServicio />
     
     
    </div>
  )
}

export default Servicios