import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient'; // Asegúrate de tener configurado Supabase
import styled from 'styled-components';

const FormContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormTitle = styled.h2`
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FormButton = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

function EliminarServicio() {
  const [servicios, setServicios] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState('');

  useEffect(() => {
    const fetchServicios = async () => {
      const { data, error } = await supabase
        .from('servicios')
        .select('*');

      if (error) {
        console.error('Error al obtener los servicios:', error.message);
        alert('Error al obtener los servicios');
      } else {
        setServicios(data);
      }
    };

    fetchServicios();
  }, []);

  const handleDelete = async () => {
    if (!selectedServiceId) {
      alert('Debes seleccionar un servicio para eliminar');
      return;
    }

    const { error } = await supabase
      .from('servicios')
      .delete()
      .eq('id', selectedServiceId);

    if (error) {
      console.error('Error al eliminar el servicio:', error.message);
      alert('Error al eliminar el servicio');
    } else {
      alert('Servicio eliminado exitosamente');
      setServicios(servicios.filter(service => service.id !== selectedServiceId));
      setSelectedServiceId('');
    }
  };

  return (
    <FormContainer>
      <FormTitle>Eliminar Servicio</FormTitle>
      <FormGroup>
        <FormSelect
          value={selectedServiceId}
          onChange={(e) => setSelectedServiceId(e.target.value)}
        >
          <option value="">Selecciona un servicio</option>
          {servicios.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name} - {service.description}
            </option>
          ))}
        </FormSelect>
      </FormGroup>
      <FormButton onClick={handleDelete}>Eliminar Servicio</FormButton>
    </FormContainer>
  );
}

export default EliminarServicio;
