import React from 'react'
import InisioSesion from './InicioSesion';

const Dashboard = () => {
  return (
    <div className='dash'>
  <InisioSesion/>
     
     
    </div>
  )
}

export default Dashboard