import React from 'react';
import styled from 'styled-components';

// Creamos un componente con Styled Components
const PaymentMethodsContainer = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const PaymentMethodsTitle = styled.h4`
  color: #333;
  margin-bottom: 10px;
  text-align:center

`;

const PaymentMethodsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PaymentMethodCard = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  margin-right: 0px;
  margin-bottom: 10px;
  flex: 1 0 300px; /* Fuerza las tarjetas a tener un ancho máximo de 300px */
  text-align: center;
  height:auto;
  
  @media only screen and (min-width: 768px) {
 margin-right: 15px;
    }
`;

const PaymentMethodName = styled.h5`
  color: #333;
  margin-bottom: 5px;
  
`;

const PaymentMethodDescription = styled.p`
  color: #666;
  font-size:14px
`;

const PaymentMethodImage = styled.img`
  max-width: 100%;
  height: 120px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const PaymentMethodsComponent = ({ paymentMethods }) => {
  return (
    <PaymentMethodsContainer>
      <PaymentMethodsTitle>Aceptamos todos estos métodos de Pago</PaymentMethodsTitle>
      <PaymentMethodsList>
        {paymentMethods.map((method, index) => (
          <PaymentMethodCard key={index}>
            <PaymentMethodImage src={method.imageUrl} alt={method.name} />
            <PaymentMethodName>{method.name}</PaymentMethodName>
            <PaymentMethodDescription>{method.description}</PaymentMethodDescription>
          </PaymentMethodCard>
        ))}
      </PaymentMethodsList>
    </PaymentMethodsContainer>
  );
};

export default PaymentMethodsComponent;
